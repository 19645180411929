import React from 'react';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import { Page, Header, Content, ContentHeader, SupportButton, InfoCard } from '@backstage/core-components';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    overviewCard: {
        marginBottom: theme.spacing(4),
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
    componentCard: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    gridContainer: {
        width: '100%',
    },
    linkContainer: {
        marginTop: 'auto',
        display: 'flex',
        justifyContent: 'flex-start',
        gap: theme.spacing(2),
    },
    link: {
        color: theme.palette.common.white,
        '&:hover': {
            textDecoration: 'none',
            opacity: 0.8,
        },
    },
    carSalesCard: {
        backgroundColor: '#1769aa',
    },
    myPorscheCard: {
        backgroundColor: '#357a38',
    },
    aiCard: {
        backgroundColor: '#6d1b7b',
    },
}));

const resourcesData = [
    {
        title: 'Car Sales',
        description:
            'The Car Sales Technology Radar is a tool to visualize and track the adoption of new technologies, tools, languages, and practices within our Car Sales ARTs. This project aims to provide a customizable and interactive interface to help teams make informed decisions about technology adoption.',
        links: [
            { title: 'Tech Radar', url: 'https://cs.techradar.porsche.digital' },
            { title: 'GitHub', url: 'https://github.com/porsche-customer/cs-technology-radar' },
        ],
        className: 'carSalesCard',
    },
    {
        title: 'My Porsche',
        description: 'Welcome to the My Porsche Technology Radar.',
        links: [
            { title: 'Tech Radar', url: 'https://myp.techradar.porsche.digital' },
            { title: 'GitHub', url: 'https://github.com/porsche-customer/myp-technology-radar' },
        ],
        className: 'myPorscheCard',
    },
    {
        title: 'AI',
        description: 'Welcome to the AI Technology Radar project!',
        links: [
            { title: 'Tech Radar', url: 'https://ai.techradar.porsche.digital' },
            { title: 'GitHub', url: 'https://github.com/porschedigital/ai-tech-radar' },
        ],
        className: 'aiCard',
    },
];

export const TechRadarOverview: React.FC = () => {
    const classes = useStyles();

    return (
        <Page themeId='home'>
            <Header title='Technology Radar Overview' subtitle='Visualize and track technology adoption' />
            <Content>
                <ContentHeader title=''>
                    <SupportButton>Need help with Tech Radar? Contact the Tech Radar team.</SupportButton>
                </ContentHeader>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12}>
                        <InfoCard title='About Technology Radar' className={classes.overviewCard}>
                            <Typography variant='body1'>
                                The Technology Radar is a tool to visualize and track the adoption of new technologies, tools, languages,
                                and practices. This is a collection of all Porsche Technology Radars, helping teams make informed decisions
                                about technology adoption.
                            </Typography>
                        </InfoCard>
                    </Grid>
                    {resourcesData.map((resource) => (
                        <Grid item xs={12} md={6} key={resource.title}>
                            <InfoCard
                                title={resource.title}
                                className={`${classes.componentCard} ${classes[resource.className as keyof typeof classes]}`}
                            >
                                <Typography variant='body2' style={{ marginBottom: '16px' }}>
                                    {resource.description}
                                </Typography>
                                <div className={classes.linkContainer}>
                                    {resource.links.map((link) => (
                                        <Button
                                            key={link.url}
                                            variant='outlined'
                                            className={classes.link}
                                            href={link.url}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            {link.title}
                                        </Button>
                                    ))}
                                </div>
                            </InfoCard>
                        </Grid>
                    ))}
                </Grid>
            </Content>
        </Page>
    );
};
